import { userAxios } from '../../index'

const diagnosesList = async perPage => {
  try {
    return await userAxios.get(`insurance/diagnosis/all/${perPage}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosesCreate = async data => {
  try {
    return await userAxios.post('insurance/diagnosis', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosesUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/diagnosis/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosesDelete = async id => {
  try {
    return await userAxios.delete(`insurance/diagnosis/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosesPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/diagnosis/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosesFilter = async (paginate, data) => {
  try {
    return await userAxios
      .post(`insurance/diagnosis/search/${paginate}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  diagnosesList,
  diagnosesCreate,
  diagnosesUpdate,
  diagnosesDelete,
  diagnosesPagination,
  diagnosesFilter,
}
