<template>
  <b-modal
    id="cost"
    v-model="conditionRefund"
    hide-footer
    hide-header
    centered
    content-class="transparent"
    size="md"
    ref="CostModal"
    @hide="onHide"
  >
    <b-card class="position-static" body-border-variant="primary">
    <h3>Consent for Treatment</h3>
    <p>{{dataTreatment.treatment}}</p>
    <!-- <p>{{dataTreatment}}</p> -->
      <!-- <b-form-input
        disabled
        :value="`${dataTreatment.user.name} ${dataTreatment.user.lastname}`"
      >
      </b-form-input> -->

      <!-- <label for="" class="mt-2">Treatment</label>
      <b-form-input disabled :value="`${dataTreatment.item.name}`">
      </b-form-input> -->

      <div class="d-flex">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="20vh"
          ref="signaturePad"
          class="border border-primary rounded mt-2"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
        <div class="align-self-center">
          <b-button
            class="btn-icon align-self-center mx-1 cursor-pointer"
            variant="danger"
            @click="clearSignature"
          >
            <i class="far fa-trash-alt"></i>
          </b-button>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="button"
          class="mr-2"
          variant="outline-danger"
          @click="onHide"
        >
          {{ $t("Cancel") }}
        </b-button>
        <b-button variant="primary" @click="onSubmit">
          <span v-if="!loading">{{ $t("Add") }}</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </div>
    </b-card>
    <ToastNotification ref="toast" />
  </b-modal>
</template>

<script>
import { BModal, BCard, BButton, BFormInput } from "bootstrap-vue";
import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import { notificationCheck } from "@/mixins/NotificationSetup";

import axiosP from "@/core/services/apiInsurance/appointment";
import axiosEC from "@/core/services/apiInsurance/external-settings/cloudinary";

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BFormInput,
    SpinnerLoading,

    ToastNotification,
  },
  props: {
    dataTreatment: {
      type: Object,
    },
  },
  data() {
    return {
      conditionRefund: true,
      fields: [{ key: "id", sortable: true }, { key: "note" }],
      loading: false,
      signatureConsent: null,
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalConset", false);
    },
    saveSignature() {
      const { isEmpty } = this.$refs.signaturePad.saveSignature();
      return !isEmpty;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    async onSubmit() {
      const savePhoto = this.saveSignature();
      if (savePhoto) {
        const { data } = this.$refs.signaturePad.saveSignature();
        const photoUrl = await this.uploadPhoto(data);

        const dataForm = {
          signature: photoUrl,
          treatments_id: this.dataTreatment.id,
        };
            this.$emit("checkConsent", true);
            this.$emit("closeModalConset", true);
        // const { office_visit_id } = this.dataTreatment.item.pivot;
        // axiosP
        //   .patientTreatmentConsent(office_visit_id, dataForm)
        //   .then((res) => {
        //     const { type, message } = notificationCheck(res);
        //     this.$refs.toast[type](message);
        //   });
      }
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("file", file);
      const { secure_url } = await axiosEC.createImage(formData);
      return secure_url;
    },
  },
};
</script>

<style lang="scss">
.transparent {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
