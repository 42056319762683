<template>
  <div class="mt-2">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Charges and Costs</h5>
        <small class="text-muted">Enter the costs of the procedure</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Consultation" label-for="consultation">
          <validation-provider #default="{ errors }" name="Consultation" rules="required">
            <b-form-input
              id="consultation"
              v-model="consultation.price"
              disabled
              :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Diagnostic imagings" label-for="diagnostic-imagings">
          <validation-provider
            #default="{ errors }"
            name="Diagnostic imagings"
            rules="required">
            <b-form-input
              id="diagnostic-imagings"
              disabled
              :value="totalPriceDiagnosticImaging"
              :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Subtotal" label-for="subtotal">
          <validation-provider #default="{ errors }" name="Subtotal" rules="required">
            <b-form-input
              id="subtotal"
              :value="subtotalPrice"
              disabled
              :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group label="Adjustment" label-for="adjustment">
          <validation-provider #default="{ errors }" name="Adjustment" rules="required">
            <b-form-input
              id="adjustment"
              v-model="getCostProcedure.adjusment"
              :state="errors.length > 0 ? false : null"
              :disabled="checkCupon || getCostProcedure.is_cupon || membership != null"
              @change="$emit('totalAdjustment', getCostProcedure.adjusment)"
              type="number" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="2" v-if="!membership" align-self="center">
        <b-form-group label="Cupon $30 OFF">
          <b-form-checkbox
            id="cupon"
            v-model="getCostProcedure.is_cupon"
            :disabled="checkCupon" />
        </b-form-group>
      </b-col> -->
      <!-- <b-col cols="2" v-else align-self="center">
        <b-button size="sm" @click="seeDiscounts">See the discounts</b-button>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Total" label-for="total with copay">
          <validation-provider #default="{ errors }" name="Total" rules="required">
            <b-form-input
              id="total"
              disabled
              :value="totalPrice"
              :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <slot></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="getCostProcedure.authorization">
          IM {{ detailsPatient.name }} {{ detailsPatient.lastName }} Accept terms and
          conditions explained by the medical team of Eye clinic
        </b-form-checkbox>
      </b-col>
    </b-row>
    <checkConsent
      v-if="checkBool"
      :dataTreatment="dataTreatment"
      @closeModalConset="closeConcent($event)"
      @checkConsent="checkCont($event)" />
    <CostOverrun v-if="conditionOverrun" @backdropEvent="closeOverrun()" />
    <seeDiscounts
      v-if="discountsBool"
      :discounts="jointDiscounts"
      @backdropEvent="closeDiscounts()" />
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox } from 'bootstrap-vue'
import checkConsent from '../../recipe/checkConsent.vue'
import { getTotal } from '@/mixins/cupon'

import CostOverrun from '../../notification/CostOverrun.vue'
import seeDiscounts from '../../notification/seeDiscounts.vue'

export default {
  components: {
    checkConsent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    CostOverrun,
    seeDiscounts,
  },
  props: {
    treatmentProcedure: {
      type: Array,
      required: true,
    },
    totalPriceDiagnosticImaging: {
      type: Number,
      default: 0,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceLaboratoryPack: {
      type: Number,
      default: 0,
    },
    totalPriceInterventions: {
      type: Number,
      default: 0,
    },
    totalPriceTreatment: {
      type: Number,
      default: 0,
    },
    totalPricePackage: {
      type: Number,
      default: 0,
    },
    getCostProcedure: {
      type: Object,
      required: true,
    },
    detailsPatient: {
      type: Object,
      required: true,
    },
    consultation: {
      type: Object,
      required: true,
    },
    campaign_cupon: {
      type: Object,
      required: false,
    },
    membership: {
      type: Object,
      required: false,
    },
    laboratorySelected: {
      type: Object,
      required: true,
    },
    interventionsProcedure: {
      type: Object,
      required: true,
    },
    diagnosticSelected: {
      type: Object,
      required: true,
    },
    procedurePacks: {
      type: Object,
      required: true,
    },
  },
  watch: {
    totalPrice(value) {
      this.$emit('totalPrice', value)
    },
    getCostProcedure: {
      handler() {
        if (this.getCostProcedure.is_cupon == true) this.getCostProcedure.adjusment = 30
        this.$emit('is_cupon', this.getCostProcedure.is_cupon)
      },
      deep: true,
    },
  },
  data() {
    return {
      dataTreatment: null,
      signedTreatment: false,
      checkBool: false,
      options: {
        penColor: '#000',
      },
      membershipDiscountApplied: false,
      selected: false,
      conditionOverrun: false,
      discountsBool: false,
      jointDiscounts: {
        consultations: {},
        laboratories: {},
        procedures: {},
        laboratories_pack: {},
        diagnostic_imagings: {},
        tests: {},
        treatments: {},
        discount_membership: {},
      },
    }
  },
  created() {
    this.$store.state.subtotalPrice = 0
  },
  computed: {
    checkCupon() {
      return this.campaign_cupon ? true : false
    },
    subtotalPrice() {
      console.log("🚀 ~ subtotalPrice ~ this.totalPriceLaboratoryPack:", this.totalPriceLaboratoryPack)
      this.membershipDiscountApplied = false
      if (this.campaign_cupon) this.getCostProcedure.adjusment = 0
      if (this.membership) this.getAdjustment()
      this.$store.state.subtotalPrice =
        this.totalPriceDiagnosticImaging +
        this.totalPriceLaboratory +
        this.totalPriceLaboratoryPack +
        this.totalPriceInterventions +
        this.totalPriceTreatment +
        this.totalPricePackage +
        this.consultation.price
      return this.$store.state.subtotalPrice
    },
    totalPrice() {
      this.checkCont()
      let newTotal
      // console.log(
      //   this.totalPriceDiagnosticImaging,
      //   this.totalPriceLaboratory,
      //   this.totalPriceInterventions,
      //   this.totalPriceTreatment,
      //   this.totalPricePackage,
      //   this.consultation.price,
      //   this.getCostProcedure.is_cupon,
      //   this.campaign_cupon,
      //   this.membershipDiscountApplied,
      //   this.getCostProcedure.adjusment,
      //   this.membership
      // )
      if (
        this.totalPriceDiagnosticImaging == 0 &&
        this.totalPriceLaboratory == 0 &&
        this.totalPriceInterventions == 0 &&
        this.totalPriceTreatment == 0 &&
        this.totalPricePackage == 0 &&
        this.membership &&
        this.getCostProcedure.adjusment != null
      ) {
        this.getCostProcedure.adjusment = this.subtotalPrice
        newTotal = this.subtotalPrice - parseFloat(this.getCostProcedure.adjusment)
      } else {
        newTotal = this.subtotalPrice - parseFloat(this.getCostProcedure.adjusment)
      }

      if (this.campaign_cupon) {
        const value = this.campaign_cupon.campaign.value
        const type = this.campaign_cupon.campaign.type
        newTotal = getTotal({
          campaignType: type,
          total: newTotal,
          campaignValue: value,
        })

        if (
          this.totalPriceDiagnosticImaging == 0 &&
          this.totalPriceLaboratory == 0 &&
          this.totalPriceInterventions == 0 &&
          this.totalPriceTreatment == 0 &&
          this.totalPricePackage == 0 &&
          this.membership &&
          this.getCostProcedure.adjusment != null
        ) {
          this.getCostProcedure.adjusment = this.subtotalPrice
          // console.log("🚀 ~ totalPrice ~ this.subtotalPrice:", this.subtotalPrice)
          this.$emit('is_cupon', this.getCostProcedure.is_cupon)
          this.$emit('totalAdjustment', this.getCostProcedure.adjusment)
        } else {
          this.getCostProcedure.adjusment = this.subtotalPrice - newTotal
          // console.log("🚀 ~ totalPrice ~ newTotal:", newTotal)
          // console.log("🚀 ~ totalPrice ~ this.subtotalPrice:", this.subtotalPrice)
          this.$emit('is_cupon', this.getCostProcedure.is_cupon)
          this.$emit('totalAdjustment', this.getCostProcedure.adjusment)
        }
      }
      // console.log('adjusment', this.getCostProcedure.adjusment)
      return newTotal + this.detailsPatient?.copay
    },
  },
  methods: {
    seeDiscounts() {
      this.discountsBool = true
    },
    dateValidation(item) {
      return item.count_available > 0 && new Date(item.activation_date) <= new Date()
    },
    verifySelectedItems(param, item) {
      let newItem = false

      if (param == 'treatments')
        newItem = this.treatmentProcedure
          .filter(e => e.cant)
          .find(({ id }) => id == item.id)

      if (param == 'laboratories') {
        Object.keys(this.laboratorySelected).forEach(labo => {
          newItem = this.laboratorySelected[labo].find(({ id }) => id == item.id)
          newItem.count_available = item.count_available ? item.count_available : 0
        })
      }

      if (param == 'laboratories_pack')
        newItem = this.procedurePacks.laboratoriesPack.find(({ id }) => id == item.id)

      if (param == 'procedures')
        newItem = this.interventionsProcedure.procedures.find(({ id }) => id == item.id)

      if (param == 'diagnostic_imagings')
        newItem = Object.keys(this.diagnosticSelected).find(e => e == item.name)

      if (param == 'tests')
        newItem = this.interventionsProcedure.tests.find(({ id }) => id == item.id)

      return newItem
    },
    getObjectDescount(param) {
      const validDiscounts = []
      let total = 0
      if (!this.membership.memberships_parameters)
        return { discounts: validDiscounts, total }
      if (!this.membershipDiscountApplied) {
        this.membershipDiscountApplied = true
        return {
          discounts: ['discount_membership'],
          total: this.membership.discount_membership,
        }
      }
      this.membership.memberships_parameters[param].forEach((item, index) => {
        let newItem = {}
        const date = this.dateValidation(item)
        if (param == 'consultations' && date) {
          total += this.getPriceDescount(param, index)
          newItem = item
        }
        if (item.price && date && this.verifySelectedItems(param, item)) {
          if (param == 'treatments') {
            total += this.getPriceDescount(
              param,
              index,
              this.verifySelectedItems(param, item)
            )
          } else {
            total += this.getPriceDescount(param, index)
          }

          newItem = item
        }
        if (newItem.id) validDiscounts.push(newItem)
      })
      return { discounts: validDiscounts, total }
    },
    getPriceDescount(param, index, treatment = null) {
      let total = 0
      const item = this.membership.memberships_parameters[param][index]
      const date = this.dateValidation(item)
      if (param == 'consultations' && date) total = this.consultation.price
      if (param == 'treatments' && item.price && date) {
        total +=
          treatment.cant <= item.count_available
            ? item.price * treatment.cant
            : item.price * item.count_available
        return total
      }
      if (item.price && date) {
        total += item.price
      }
      return total
    },
    getAdjustment() {
      if (!this.membership || this.getCostProcedure.adjusment == null) return
      if (!this.membership) return this.getCostProcedure.adjusment

      const baseObject = {
        total: 0,
        discounts: [],
      }
      let total = 0,
        consultations = { ...baseObject },
        laboratories = { ...baseObject },
        procedures = { ...baseObject },
        laboratories_pack = { ...baseObject },
        diagnostic_imagings = { ...baseObject },
        tests = { ...baseObject },
        treatments = { ...baseObject },
        discount_membership = { ...baseObject }

      if (!this.membershipDiscountApplied) {
        const membershipDiscount = this.membership.discount_membership || 0
        this.getCostProcedure.adjusment -= membershipDiscount
        discount_membership = this.getObjectDescount('discount_membership')
      }

      consultations = this.getObjectDescount('consultations')
      laboratories = this.getObjectDescount('laboratories')
      procedures = this.getObjectDescount('procedures')
      laboratories_pack = this.getObjectDescount('laboratories_pack')
      diagnostic_imagings = this.getObjectDescount('diagnostic_imagings')
      tests = this.getObjectDescount('tests')
      treatments = this.getObjectDescount('treatments')

      this.jointDiscounts = {
        consultations,
        laboratories,
        procedures,
        laboratories_pack,
        diagnostic_imagings,
        tests,
        treatments,
        discount_membership,
      }

      total += consultations.total
      total += laboratories.total
      total += procedures.total
      total += laboratories_pack.total
      total += diagnostic_imagings.total
      total += tests.total
      total += treatments.total
      total += discount_membership.total

      if (
        this.totalPriceDiagnosticImaging == 0 &&
        this.totalPriceLaboratory == 0 &&
        this.totalPriceInterventions == 0 &&
        this.totalPriceTreatment == 0 &&
        this.totalPricePackage == 0
      ) {
        this.getCostProcedure.adjusment = this.consultation.price
      } else {
        this.getCostProcedure.adjusment = total
      }
    },
    trueOverrun() {
      this.conditionOverrun = true
    },
    closeDiscounts() {
      this.discountsBool = false
    },
    closeOverrun() {
      this.conditionOverrun = false
    },
    closeConcent(concent) {
      this.$emit('noKen', concent)
      this.checkBool = false
    },
    checkCont(signed) {
      this.checkBool = false
      if (signed == true && signed != undefined) {
        this.signedTreatment = true
        return (this.checkBool = false)
      }
      const kenalog = this.treatmentProcedure.find(
        treatment => treatment.nameTreatment == 'kenalog'
      )
      if (kenalog?.cant != null && this.signedTreatment != true) {
        this.checkBool = true
        kenalog.name = 'KENALOG-40 MG INJECTION'
        kenalog.treatment =
          "Kenalog Injection is a glucocorticoid synthetic used for its action anti-inflammatory. It is indicated for the treatment of allergic processes, acne, injuries dermatological, gout, rheumatoid arthritis, osteoarthritis, multiple sclerosis and injuries inflammatory, among others. Adverse reactions at the injection site injection “include: — skin atrophy and subcutaneous, dry scaly skin, ecchymosis and petechiae, edema, erythema, hyperpigmentation and hypopigmentation, skin fine and brittle, among others. Other side effects may be: pain headache, dizziness, increased blood pressure blood pressure and sugar levels, nausea, vomiting, among others. As with all medical procedures, there are risks associated with administration of any medication. Without However, it is more likely that this procedure gives you a benefit. This benefit outweighs the small risk, but for course will be based on your specific case and with the consideration of the suppliers of your complete medical history. I hereby give my consent for the Kenalog injection-40 MG and I have informed Hispano Medical Group of each and every one of health problems you may have and the medications I'm taking currently or to which I am allergic. To have this medicine given to you, sign the following consent"
        kenalog.user = this.detailsPatient
        this.dataTreatment = kenalog
      }
    },
  },
}
</script>

<style></style>
