import { userAxios } from '../../index'

const testList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/inHouseTests/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testCreate = async data => {
  try {
    return await userAxios.post('insurance/inHouseTests', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/inHouseTests/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testDelete = async id => {
  try {
    return await userAxios.delete(`insurance/inHouseTests/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/inHouseTests/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/inHouseTests/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const testFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/inHouseTests/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  testList,
  testCreate,
  testUpdate,
  testDelete,
  testPagination,
  testFilter,
  testFilterPagination,
}
