<template>
    <div>
        <b-row class="mt-2">
            <b-col class="mb-2">
                <h5 class="mb-0">Laboratory</h5>
                <small class="text-muted">Enter the patient's labs.</small>
            </b-col>
            <b-col v-if="laboratoryPromotion.length > 0" class="mb-1">
                <label for="">Promotions</label>
                <v-select :components="{ Deselect }" id="mySelect" :noDrop="true" :searchable="false"
                    v-model="laboratoryPromotion" multiple label="name" />
            </b-col>
            <!-- <b-col>
                <h3 class="d-flex justify-content-end">$ {{ totalPriceLaboratory }}</h3>
            </b-col> -->
        </b-row>
        <b-row>
            <b-col md="4" lg="6" xl="4" class="my-1" v-for="item in laboratoryLength.data" :key="item.id">
                <b-form-group :label-for="item.name">
                    <div class="d-flex justify-content-between">
                        <label for="">{{ item.name }}</label>
                        <b-form-checkbox v-if="item.price != null" name="checkbox-input"
                            v-model="checkCategoryVariable[item.id]" @change="checkCategory($event, item)" />
                    </div>
                    <treeselect id="mySelect" :disabled="checkCategoryVariable[item.id]"
                        v-model="laboratorySelected[item.name]" :options="item.laboratoriesM" :normalizer="normalizer"
                        value-format="object" multiple>
                    </treeselect>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    VBTooltip,
} from "bootstrap-vue";

import axiosLC from "@/core/services/apiInsurance/admin/consults/laboratoryCategories";

/* import LaboratoryAdd from "@/views/admin/type-consult/laboratory/LaboratoryAdd.vue"; */

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormCheckbox,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    props: {
        laboratoryPromotion: {
            type: Array,
            required: true,
        },
        laboratorySelected: {
            type: Object,
            required: true,
        },
        laboratoryLength: {
            type: Object,
            required: true,
        },
        procedurePacks: {
            type: Object,
            required: true,
        },
        totalPriceLaboratory: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            checkCategoryVariable: this.procedurePacks.checkCategoryLaboratory,
            isAddNewLaboratorySidebarActive: false,
            setTimeoutBuscador: "",
            Deselect: {
                render: (createElement) => createElement("span"),
            },
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    watch: {
        laboratorySelected: {
            handler(newValue) {
                const laboratories = this.laboratoryLength.data.filter(laboratory => newValue.hasOwnProperty(laboratory.name))
                laboratories.map((laboratory) => {
                    if (newValue[laboratory.name].length == laboratory.laboratoriesM.length && laboratory.price != null) {
                        this.checkCategoryVariable[laboratory.id] = true
                        this.checkCategory(true, laboratory)
                    }
                })
            },
            deep: true
        }
    },
    methods: {
        validResultsList(item) {
            if (item != undefined) {
                const names = [];
                item.forEach((element) => {
                    if (element.pivot.status === false) {
                        names.push(element.name);
                    }
                });
                if (names.length > 0) {
                    return [
                        {
                            status: true,
                            names: names.join(", "),
                        },
                    ];
                } else {
                    return item;
                }
            }
            return item;
        },
        getReviewSystemCategory() {
            axiosLC.categoryLabList(20).then(({ data }) => {
                const catLaboratories = data.map(({ laboratories, ...res }) => {
                    const laboratoriesM = laboratories.filter(
                        (laboratory) => laboratory.price != null
                    );
                    return {
                        laboratoriesM,
                        ...res,
                    };
                });
                this.laboratoryLength.data = catLaboratories;
            });
        },
        checkCategory(event, item) {
            if (event == true) {
                const findLab = this.procedurePacks.laboratoriesPack.filter(
                    (laboratory) => {
                        return laboratory.id === item.id;
                    }
                );
                if (findLab.length == 0) {
                    this.procedurePacks.laboratoriesPack.push(item);
                    if (Array.isArray(this.laboratorySelected[item.name])) {
                        this.laboratorySelected[item.name] = [];
                    }
                }
            } else {
                const newArray = this.procedurePacks.laboratoriesPack.filter(
                    (laboratory) => laboratory.id !== item.id
                );
                this.procedurePacks.laboratoriesPack = newArray;
            }
        },
        createLaboratory(value) {
            this.isAddNewLaboratorySidebarActive = false;
            this.getReviewSystemCategory();
        },
        filterLaboratory(name, id) {
            clearTimeout(this.setTimeoutBuscador);
            this.setTimeoutBuscador = setTimeout(() => {
                laboratories
                    .laboratoryFilter(10, { name: name, status: true, })
                    .then(({ registro: { data } }) => {
                        this.laboratoryLength[id - 1].laboratoriesM = data;
                    });
            }, 300);
        },
    },
};
</script>
<style scoped>
#mySelect>>>.vs__dropdown-menu {
    min-width: 350px;
}
</style>