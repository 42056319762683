<template>
  <b-row class="mt-2">
    <!-- <TestAdd
      :is-add-new-test-sidebar-active.sync="isAddNewTestSidebarActive"
      @createTest="createTest"
    />
    <ProceduresAdd
      :is-add-new-procedures-sidebar-active.sync="
        isAddNewProceduresSidebarActive
      "
      @createProcedures="createProcedures"
    /> -->
    <b-col cols="6" class="mb-2">
      <h5 class="mb-0">IH interventions</h5>
      <small class="text-muted">Enter the interventions.</small>
    </b-col>
    <b-col cols="6">
      <h3 class="d-flex justify-content-end">
        $ {{ totalPriceInterventions }}
      </h3>
      <h3 class="d-flex justify-content-end">Total $ {{ subtotalPrice }}</h3>
    </b-col>
    <b-col md="4" lg="5">
      <validation-provider #default="{ errors }" name="Test">
        <b-form-group
          label="Test"
          label-for="test"
          :state="errors.length > 0 ? false : null"
        >
          <treeselect
            id="mySelect"
            v-model="interventionsProcedure.tests"
            :defaultOptions="testOptions.data"
            :load-options="searchTests"
            :async="true"
            :normalizer="normalizer"
            value-format="object"
            multiple
          />

          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- <b-col md="2" lg="1" class="d-flex flex-column">
      <label for="">{{ $t("Add") }} test</label> -->
      <!-- <b-button
        variant="success"
        class="btn-icon mb-2"
        @click="isAddNewTestSidebarActive = true"
      >
        <i class="wizard-icon fas fa-plus-circle"></i>
      </b-button> -->
    <!-- </b-col> -->
    <b-col md="4" lg="5">
      <validation-provider #default="{ errors }" name="Procedure">
        <b-form-group
          label="Procedure"
          label-for="procedure"
          :state="errors.length > 0 ? false : null"
        >
          <treeselect
            id="mySelect"
            v-model="interventionsProcedure.procedures"
            :defaultOptions="true"
            :load-options="searchProcedures"
            :async="true"
            :normalizer="normalizer"
            value-format="object"
            multiple
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="checkPromotion" label="Promotion">
          <v-select
            :components="{ Deselect }"
            id="mySelect"
            v-model="promotionPorcedure"
            multiple
            :noDrop="true"
            :searchable="false"
            label="name"
          />
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- <b-col md="2" lg="1" class="d-flex flex-column">
      <label for="">{{ $t("Add") }} P/D</label>
      <b-button
        variant="success"
        class="btn-icon"
        @click="isAddNewProceduresSidebarActive = true"
      >
        <i class="wizard-icon fas fa-plus-circle"></i>
      </b-button>
    </b-col> -->
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";

/* import TestAdd from "@/views/admin/type-consult/test/TestAdd.vue";
import ProceduresAdd from "@/views/admin/type-consult/procedures/ProceduresAdd.vue"; */

import axiosT from "@/core/services/apiInsurance/admin/consults/tests";
import axiosP from "@/core/services/apiInsurance/admin/consults/procedures";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  props: {
    interventionsProcedure: {
      type: Object,
      required: true,
    },
    testOptions: {
      type: Object,
      required: true,
    },
    procedureOptions: {
      type: Object,
      required: true,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceDiagnosticImaging: {
        type: Number,
        default: 0,
    },
    totalPriceInterventions: {
        type: Number,
        default: 0,
    },
    totalPriceTreatment: {
        type: Number,
        default: 0,
    },
  },
  data() {
    return {
      setTimeoutBuscador: "",
      isAddNewTestSidebarActive: false,
      isAddNewProceduresSidebarActive: false,
      promotionPorcedure: [],
      checkPromotion: false,
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    subtotalPrice() {
        return (
            this.totalPriceDiagnosticImaging +
            this.totalPriceLaboratory +
            this.totalPriceInterventions +
            this.totalPriceTreatment
        );
    },
  },
  methods: {
    getTests() {
      axiosT.testList(100).then(({ data }) => {
        this.testOptions.data = data;
      });
    },
    getProcedures() {
      axiosP.procedureList(100).then(({ data }) => {
        const arrayUserOptions = this.promotionPorcedure;
        this.procedureOptions.data = data.filter(
          ({ id: id1 }) => !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
        );
      });
    },
    searchTests({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
          status: true,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosT.testFilter(100, datos).then(({ registro: { data } }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
    searchProcedures({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
          status: true,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosP.procedureFilter(100, datos).then(({ registro: { data } }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
    createTest(value) {
      this.isAddNewTestSidebarActive = false;
      this.getTests();
    },
    createProcedures(value) {
      this.isAddNewProceduresSidebarActive = false;
      this.getProcedures();
    },
    getData() {
      const arrayOptions = this.procedureOptions.data;
      const arrayUserOptions = this.interventionsProcedure.procedures;

      const promotions = arrayUserOptions.filter(
        (item) => item.pivot.promotion
      );

      if (promotions.length > 0) {
        this.procedureOptions.data = arrayOptions.filter(
          ({ id: id1 }) => !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
        );
      }

      promotions.forEach((element) => {
        this.checkPromotion = true;
        const index = arrayUserOptions.findIndex((x) => x.id === element.id);
        this.interventionsProcedure.procedures.splice(index, 1);
      });

      this.promotionPorcedure = promotions;
    },
  },
};
</script>

<style scoped>
#mySelect >>> .vs__dropdown-menu {
  min-width: 320px;
}
</style>
