var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Charges and Costs")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter the costs of the procedure")])])],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Consultation","label-for":"consultation"}},[_c('validation-provider',{attrs:{"name":"Consultation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"consultation","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.consultation.price),callback:function ($$v) {_vm.$set(_vm.consultation, "price", $$v)},expression:"consultation.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Diagnostic imagings","label-for":"diagnostic-imagings"}},[_c('validation-provider',{attrs:{"name":"Diagnostic imagings","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"diagnostic-imagings","disabled":"","value":_vm.totalPriceDiagnosticImaging,"state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Subtotal","label-for":"subtotal"}},[_c('validation-provider',{attrs:{"name":"Subtotal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subtotal","value":_vm.subtotalPrice,"disabled":"","state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Adjustment","label-for":"adjustment"}},[_c('validation-provider',{attrs:{"name":"Adjustment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"adjustment","state":errors.length > 0 ? false : null,"disabled":_vm.checkCupon || _vm.getCostProcedure.is_cupon || _vm.membership != null,"type":"number"},on:{"change":function($event){return _vm.$emit('totalAdjustment', _vm.getCostProcedure.adjusment)}},model:{value:(_vm.getCostProcedure.adjusment),callback:function ($$v) {_vm.$set(_vm.getCostProcedure, "adjusment", $$v)},expression:"getCostProcedure.adjusment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Total","label-for":"total with copay"}},[_c('validation-provider',{attrs:{"name":"Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"total","disabled":"","value":_vm.totalPrice,"state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_vm._t("default")],2)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.getCostProcedure.authorization),callback:function ($$v) {_vm.$set(_vm.getCostProcedure, "authorization", $$v)},expression:"getCostProcedure.authorization"}},[_vm._v(" IM "+_vm._s(_vm.detailsPatient.name)+" "+_vm._s(_vm.detailsPatient.lastName)+" Accept terms and conditions explained by the medical team of Eye clinic ")])],1)],1),(_vm.checkBool)?_c('checkConsent',{attrs:{"dataTreatment":_vm.dataTreatment},on:{"closeModalConset":function($event){return _vm.closeConcent($event)},"checkConsent":function($event){return _vm.checkCont($event)}}}):_vm._e(),(_vm.conditionOverrun)?_c('CostOverrun',{on:{"backdropEvent":function($event){return _vm.closeOverrun()}}}):_vm._e(),(_vm.discountsBool)?_c('seeDiscounts',{attrs:{"discounts":_vm.jointDiscounts},on:{"backdropEvent":function($event){return _vm.closeDiscounts()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }