import { userAxios } from '../../index'

const categoryLabList = async paginate => {
  try {
    return await userAxios
      .get(`insurance/laboratories/laboratoryCategories/all/${paginate}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabCreate = async data => {
  try {
    return await userAxios
      .post('insurance/laboratories/laboratoryCategories', data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/laboratories/laboratoryCategories/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabDelete = async id => {
  try {
    return await userAxios
      .delete(`insurance/laboratories/laboratoryCategories/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/laboratories/laboratoryCategories/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/laboratories/laboratoryCategories/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const categoryLabFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/laboratoryCategoriesoratory/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  categoryLabList,
  categoryLabCreate,
  categoryLabUpdate,
  categoryLabDelete,
  categoryLabPagination,
  categoryLabFilter,
  categoryLabFilterPagination,
}
