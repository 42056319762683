<template>
  <div>
    <b-row class="mt-2">
      <b-col class="mb-2">
        <h5 class="mb-0">Diagnostic Imaging</h5>
        <small class="text-muted">Enter the patient's diagnostic images.</small>
      </b-col>
      <b-col v-if="diagnosticPromotion.length > 0" class="mb-1">
        <label for="">Promotions</label>
        <v-select
          :components="{ Deselect }"
          id="mySelect"
          :noDrop="true"
          :searchable="false"
          v-model="diagnosticPromotion"
          multiple
          label="name"
        />
      </b-col>
      <b-col>
        <h3 class="d-flex justify-content-end">
          $ {{ totalPriceDiagnosticImaging }}
        </h3>
        <h3 class="d-flex justify-content-end">Total $ {{ subtotalPrice }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        lg="6"
        xl="4"
        class="my-1"
        v-for="item in diagnosticLength.data"
        :key="item.id"
      >
        <b-form-group :label-for="item.name">
          <div class="d-flex justify-content-between">
            <label for="">{{ item.name }}</label>
            <!-- <div
              ref=""
              v-for="(item, i) in validResultsList(
                diagnosticSelected[item.name]
              )"
              :key="i"
            >
              <b-button
                v-if="item.status"
                variant="flat-warning"
                class="btn-icon rounded-circle p-0"
                v-b-tooltip.hover.top="
                  `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`
                "
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div> -->
          </div>
          <validation-provider #default="{ errors }" :name="item.name">
            <treeselect
              id="mySelect"
              v-model="diagnosticSelected[item.name]"
              :options="item.diagnosticM"
              :normalizer="normalizer"
              value-format="object"
              multiple
            >
            </treeselect>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BRow, BCol, BFormGroup, BButton, VBTooltip } from "bootstrap-vue";

import axiosIC from "@/core/services/apiInsurance/admin/consults/diagnosticImagesCategories";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    diagnosticPromotion: {
      type: Array,
      required: true,
    },
    diagnosticSelected: {
      type: Object,
      required: true,
    },
    diagnosticLength: {
      type: Object,
      required: true,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceDiagnosticImaging: {
        type: Number,
        default: 0,
    },
    totalPriceInterventions: {
        type: Number,
        default: 0,
    },
    totalPriceTreatment: {
        type: Number,
        default: 0,
    },
  },
  data() {
    return {
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  computed: {
    subtotalPrice() {
        return (
            this.totalPriceDiagnosticImaging +
            this.totalPriceLaboratory +
            this.totalPriceInterventions +
            this.totalPriceTreatment 
        );
    },
  },
  methods: {
    getDiagnosticCategory() {
      axiosIC.diagnosticImageCategoryList(20).then(({ data }) => {
        const catImaging = data.map(({ diagnostic_imagings, ...res }) => {
          const diagnosticM = diagnostic_imagings.filter(
            (laboratory) => laboratory.price != null
          );
          return {
            diagnosticM,
            ...res,
          };
        });
        this.diagnosticLength.data = catImaging;
      });
    },
    validResultsList(item) {
      if (item != undefined) {
        const names = [];
        item.forEach((element) => {
          if (element.pivot.status === false) {
            names.push(element.name);
          }
        });
        if (names.length > 0) {
          return [
            {
              status: true,
              names: names.join(", "),
            },
          ];
        } else {
          return item;
        }
      }
      return item;
    },
  },
};
</script>
<style scoped>
#mySelect >>> .vs__dropdown-menu {
  min-width: 350px;
}
</style>
