<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div class="py-lg-5 px-lg-5">
        <div>
          <h4>Package</h4>
          <div
            v-if="packageView && packageView().name !== '' && packageView() != ''"
            class="row col-md-12">
            <p class="col-md-4 text-center">
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper">
                  {{ packageView().name }}
                </span>
              </b-badge>
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper"> ${{ calculatePricePackageView() }} </span>
              </b-badge>
            </p>

            <!-- Options Selection -->
            <b-form-group label="Options" class="col-md-4 text-center">
              <b-form-radio-group
                v-model="selectedOption"
                :name="'options_' + packageView().name"
                class="d-flex flex-column ml-6">
                <b-form-radio value="1">Self Pay</b-form-radio>
                <b-form-radio value="0">Insurance Benefit</b-form-radio>
                <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <!-- Percentage input for coinsurance -->
            <b-form-group
              v-if="selectedOption === 'coinsurance'"
              label="(%) Insurance"
              class="col-md-4">
              <b-form-input
                v-model="coinsurancePercentage"
                type="number"
                placeholder="Enter percentage"
                min="0"
                max="100">
              </b-form-input>
            </b-form-group>
          </div>
        </div>

        <hr />
        <div>
          <h4>Laboratory</h4>
          <div
            v-for="(labo, index) in laboratorySelected"
            v-bind:key="labo.Array"
            class="list-unstyled">
            <div v-for="lab in labo" v-bind:key="lab.id" class="row col-md-12 my-4">
              <!-- Sección de nombre y precio -->

              <p class="col-md-4 text-center">
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> {{ lab.name }}</span>
                </b-badge>
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> ${{ calculatePrice(lab) }}</span>
                </b-badge>
              </p>

              <b-form-group label="Options" class="col-md-4 text-center">
                <b-form-radio-group
                  v-model="lab.selectedOption"
                  :name="lab.id + index"
                  class="d-flex flex-column ml-6">
                  <b-form-radio value="1">Self Pay</b-form-radio>
                  <b-form-radio value="0">Insurance Benefit</b-form-radio>
                  <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-if="lab.selectedOption === 'coinsurance'"
                label="(%)insurance"
                class="col-md-4">
                <b-form-input
                  v-model="lab.coinsurancePercentage"
                  type="number"
                  placeholder="Ingrese porcentaje"
                  min="0"
                  max="100">
                </b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>

        <hr />
        <div>
          <h4>Laboratory package</h4>
          <span v-for="(lapac, index) in procedurePacks.laboratoriesPack" v-bind:key="lapac.id">
            <div class="row col-md-12 my-4">
              <p class="col-md-4 text-center">
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> {{ lapac.name }}</span>
                </b-badge>
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> ${{ calculatePrice(lapac) }}</span>
                </b-badge>
              </p>

              <b-form-group label="Options" class="col-md-4 text-center">
                <b-form-radio-group
                  v-model="lapac.selectedOption"
                  :name="'options___' + index"
                  class="d-flex flex-column ml-6">
                  <b-form-radio value="1">Self Pay</b-form-radio>
                  <b-form-radio value="0">Insurance Benefit</b-form-radio>
                  <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-if="lapac.selectedOption === 'coinsurance'"
                label="(%)insurance"
                class="col-md-4">
                <b-form-input
                  v-model="lapac.coinsurancePercentage"
                  type="number"
                  placeholder="Ingrese porcentaje"
                  min="0"
                  max="100">
                </b-form-input>
              </b-form-group>
            </div>
          </span>
        </div>

        <hr />
        <h4>Interventions</h4>
        <span
          v-for="(intervention, index) in interventionsProcedure"
          v-bind:key="intervention.Array">
          <div
            v-for="int in intervention"
            v-bind:key="int.name"
            class="row col-md-12 my-4">
            <p class="col-md-4 text-center">
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper"> {{ int.name }}</span>
              </b-badge>
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper"> ${{ calculatePrice(int) }}</span>
              </b-badge>
            </p>

            <b-form-group label="Options" class="col-md-4 text-center">
              <b-form-radio-group
                v-model="int.selectedOption"
                :name="int.id + index"
                class="d-flex flex-column ml-6">
                <b-form-radio value="1">Self Pay</b-form-radio>
                <b-form-radio value="0">Insurance Benefit</b-form-radio>
                <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              v-if="int.selectedOption === 'coinsurance'"
              label="(%)insurance"
              class="col-md-4">
              <b-form-input
                v-model="int.coinsurancePercentage"
                type="number"
                placeholder="Ingrese porcentaje"
                min="0"
                max="100">
              </b-form-input>
            </b-form-group>
          </div>
        </span>

        <hr />
        <div>
          <h4>Treatments</h4>
          <span v-for="(treatment, index) in treatmentView" v-bind:key="treatment.id">
            <div class="row col-md-12 my-4">
              <p class="col-md-4 text-center">
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> {{ treatment.name }}</span>
                </b-badge>
                <b-badge variant="primary" class="text-white m-4">
                  <span class="badge-wrapper"> ${{ calculatePrice(treatment) }}</span>
                </b-badge>
              </p>

              <b-form-group label="Options" class="col-md-4 text-center">
                <b-form-radio-group
                  v-model="treatment.selectedOption"
                  :name="'options___' + index"
                  class="d-flex flex-column ml-6">
                  <b-form-radio value="1">Self Pay</b-form-radio>
                  <b-form-radio value="0">Insurance Benefit</b-form-radio>
                  <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-if="treatment.selectedOption === 'coinsurance'"
                label="(%)insurance"
                class="col-md-4">
                <b-form-input
                  v-model="treatment.coinsurancePercentage"
                  type="number"
                  placeholder="Ingrese porcentaje"
                  min="0"
                  max="100">
                </b-form-input>
              </b-form-group>
            </div>
          </span>
        </div>

        <hr />
        <h4>Diagnostic imaging</h4>
        <span
          v-for="(diagnostic, index) in diagnosticSelected"
          v-bind:key="diagnostic.Array">
          <div
            v-for="diag in diagnostic"
            v-bind:key="diag.name"
            class="row col-md-12 my-4">
            <p class="col-md-4 text-center">
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper"> {{ diag.name }}</span>
              </b-badge>
              <b-badge variant="primary" class="text-white m-4">
                <span class="badge-wrapper"> ${{ calculatePrice(diag) }}</span>
              </b-badge>
            </p>

            <b-form-group label="Options" class="col-md-4 text-center">
              <b-form-radio-group
                v-model="diag.selectedOption"
                :name="diag.id + index"
                class="d-flex flex-column ml-6">
                <b-form-radio value="1">Self Pay</b-form-radio>
                <b-form-radio value="0">Insurance Benefit</b-form-radio>
                <b-form-radio value="coinsurance">Coinsurance</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              v-if="diag.selectedOption === 'coinsurance'"
              label="(%)insurance"
              class="col-md-4">
              <b-form-input
                v-model="diag.coinsurancePercentage"
                type="number"
                placeholder="Ingrese porcentaje"
                min="0"
                max="100">
              </b-form-input>
            </b-form-group>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    treatmentProcedure: {
      type: Array,
      required: true,
    },
    totalPriceDiagnosticImaging: {
      type: Number,
      default: 0,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceLaboratoryPack: {
      type: Number,
      default: 0,
    },
    totalPriceInterventions: {
      type: Number,
      default: 0,
    },
    totalPriceTreatment: {
      type: Number,
      default: 0,
    },
    totalPricePackage: {
      type: Number,
      default: 0,
    },
    getCostProcedure: {
      type: Object,
      required: true,
    },
    detailsPatient: {
      type: Object,
      required: true,
    },
    consultation: {
      type: Object,
      required: true,
    },
    campaign_cupon: {
      type: Object,
      required: false,
    },
    membership: {
      type: Object,
      required: false,
    },
    laboratorySelected: {
      type: Object,
      required: true,
    },
    interventionsProcedure: {
      type: Object,
      required: true,
    },
    diagnosticSelected: {
      type: Object,
      required: true,
    },
    procedurePacks: {
      type: Object,
      required: true,
    },
    treatmentOptions: {
      type: Array,
      required: true,
    },
    package: {
      type: Object,
      required: true,
    },
  },
  watch: {
    laboratorySelected: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
    laboratorySelected: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
    selectedOption() {
      this.updateTotalPriceLaboratory()
    },
    interventionsProcedure: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
    diagnosticSelected: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
    treatmentProcedure: {
      handler() {
        this.updateTreatmentView()
      },
      deep: true,
    },
    treatmentOptions: {
      handler() {
        this.updateTreatmentView()
      },
      deep: true,
    },
    treatmentView: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
    procedurePacks: {
      handler() {
        this.updateTotalPriceLaboratory()
      },
      deep: true,
    },
  },
  mounted() {
    this.updateTotalPriceLaboratory()
  },
  data() {
    return {
      selectedOption: '1',
      coinsurancePercentage: this.detailsPatient?.co_insurance || 0,
      treatmentView: [],
      lab: [],
      isTreatmentViewInitialized: false,
    }
  },
  methods: {
    updateSelected() {
      Object.keys(this.laboratorySelected).forEach(key => {
        this.laboratorySelected[key].forEach(lab => {
          lab['coinsurancePercentage'] = this.detailsPatient?.co_insurance || 0
        })
      })
      Object.keys(this.interventionsProcedure).forEach(key => {
        this.interventionsProcedure[key].forEach(int => {
          int['coinsurancePercentage'] = this.detailsPatient?.co_insurance || 0
        })
      })
      this.procedurePacks.laboratoriesPack.forEach(laboratoriesPack => {
        laboratoriesPack['coinsurancePercentage'] = this.detailsPatient?.co_insurance || 0
      })
      this.treatmentView.forEach(treatment => {
        treatment['coinsurancePercentage'] = this.detailsPatient?.co_insurance || 0
      })
      Object.keys(this.diagnosticSelected).forEach(key => {
        this.diagnosticSelected[key].forEach(diag => {
          diag['coinsurancePercentage'] = this.detailsPatient?.co_insurance || 0
        })
      })
      const packageViewSeleted = {
        selectedOption: this.selectedOption,
        coinsurancePercentage: this.coinsurancePercentage,
      }
      this.$emit('insuranceDiscount', {
        packageView: packageViewSeleted,
        laboratorySelected: this.laboratorySelected,
        interventionsProcedure: this.interventionsProcedure,
        treatmentView: this.treatmentView,
        diagnosticSelected: this.diagnosticSelected,
        laboratoriesPack: this.procedurePacks.laboratoriesPack,
      })
      // console.log('🚀 ~ updateSelected ~ this.treatmentView:', this.treatmentView)
    },
    updateTreatmentView() {
      let treatment = this.treatmentProcedure.filter(treat => treat.cant != null),
        treatments = []

      treatment.forEach(treat => {
        this.treatmentOptions.forEach(treats => {
          if (treats.id === treat.id) {
            treatments.push(treats)
          }
        })
      })

      if (!this.isTreatmentViewInitialized) {
        // Primera inicialización: usa treatmentView de detailsPatient o los nuevos treatments
        this.treatmentView = this.detailsPatient.treatmentView || treatments
        this.isTreatmentViewInitialized = true
      } else {
        // Actualiza conservando lo que ya tenía treatmentView
        const existingIds = this.treatmentView.map(treat => treat.id) // IDs ya presentes
        treatments.forEach(newTreat => {
          if (!existingIds.includes(newTreat.id)) {
            this.treatmentView.push(newTreat) // Agrega solo los nuevos tratamientos
          }
        })
      }

      // console.log(
      //   '🚀 ~ updateTreatmentView ~ this.detailsPatient.treatmentView:',
      //   treatments,
      //   this.treatmentView
      // )
    },
    clearSignature() {
      this.$refs.signaturePad.clear()
    },
    calculatePrice(lab) {
      if (lab.selectedOption === '0') {
        return 0
      } else if (lab.selectedOption === 'coinsurance' && lab.coinsurancePercentage > 0) {
        console.log("🚀 ~ calculatePrice ~ (lab.price * lab.coinsurancePercentage) / 100:", (lab.price * lab.coinsurancePercentage) / 100)
        return (lab.price * lab.coinsurancePercentage) / 100
      }
      return lab.price
    },
    packageView() {
      if (!this.package.data) return ''
      let pkg = this.package.all.find(pack => pack.id == this.package.data)
      return pkg || {}
    },
    calculatePricePackageView() {
      let pkg = this.packageView() // Obtenemos el paquete seleccionado
      if (!pkg || !pkg.price) return 0 // Si no hay paquete o no tiene precio, retornamos 0

      // Dependiendo de la opción seleccionada, ajustamos el precio
      if (this.selectedOption === '0') {
        return 0 // Si se seleccionó "Price 0", retornamos 0
      } else if (
        this.selectedOption === 'coinsurance' &&
        this.coinsurancePercentage > 0
      ) {
        return (pkg.price * this.coinsurancePercentage) / 100 // Si es coinsurance, aplicamos el porcentaje
      }

      // Si no se seleccionó "None" ni "coinsurance", retornamos el precio completo
      return pkg.price
    },
    updateTotalPriceLaboratory() {
      this.updateSelected()
      let total = 0

      let totalPrice =
        this.totalPriceDiagnosticImaging +
        this.totalPriceLaboratory +
        this.totalPriceInterventions +
        this.totalPriceTreatment +
        this.totalPricePackage
        + this.totalPriceLaboratoryPack 

      // Calculamos el total de los laboratorios seleccionados
      for (const key in this.laboratorySelected) {
        this.laboratorySelected[key].forEach(lab => {
          total += this.calculatePrice(lab) // Precio de laboratorio
        })
      }
      // console.log("🚀 ~ updateTotalPriceLaboratory ~ total:", total)

      // Calculamos el precio del paquete y lo sumamos
      const packagePrice = this.calculatePricePackageView()
      total += packagePrice // Sumamos el precio del paquete al total

      for (const key in this.interventionsProcedure) {
        this.interventionsProcedure[key].forEach(lab => {
          total += this.calculatePrice(lab) // Precio de laboratorio
        })
      }

      // Calculamos el precio de los tratamientos seleccionados
      this.treatmentView.forEach(lab => {
        total += this.calculatePrice(lab)
      })

      this.procedurePacks.laboratoriesPack.forEach(lab => {
        total += this.calculatePrice(lab)
      })
      // console.log("🚀 ~ updateTotalPriceLaboratory ~ total:", total)

      for (const key in this.diagnosticSelected) {
        this.diagnosticSelected[key].forEach(lab => {
          total += this.calculatePrice(lab) // Precio de laboratorio
        })
      }

      total = totalPrice - total
      // Si el total es menor a 0, se establece en 0
      total = total < 0 ? 0 : total

      // Imprimimos los valores para verificación
      // console.log("��� ~ updateTotalPriceLaboratory ~ totalPriceLaboratory:", this.totalPriceLaboratory)
      // console.log("🚀 ~ updateTotalPriceLaboratory ~ this.totalPriceLaboratoryPack:", this.totalPriceLaboratoryPack)
      // console.log("🚀 ~ updateTotalPriceLaboratory ~ total:", total)
      // console.log("🚀 ~ updateTotalPriceLaboratory ~ totalPrice:", totalPrice)

      // Realizamos el ajuste de precio de laboratorio, restando el total calculado
      // Emitimos el total ajustado al componente padre

      // console.log('🚀 ~ mounted ~ this.laboratorySelected:', this.laboratorySelected)
      // console.log(
      //   '🚀 ~ mounted ~ this.interventionsProcedure:',
      //   this.interventionsProcedure
      // )
      // console.log('🚀 ~ mounted ~ this.treatmentView:', this.treatmentView)
      // console.log('🚀 ~ mounted ~ this.diagnosticSelected:', this.diagnosticSelected)

      this.$emit('totalAdjustment', total)
      // console.log('🚀 ~ updateTotalPriceLaboratory ~ total:', total)
    },
  },
}
</script>

<style></style>
