<template>
    <div>
        <b-row class="mt-2">
            <b-col cols="10" class="mb-2">
                <h5 class="mb-0">Packages</h5>
                <small class="text-muted">Enter package of treatments.</small>
            </b-col>
            <b-col>
                <h3 class="d-flex justify-content-end">$ {{ price }}</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Package" label-for="Package">
                    <!-- Agregar Buscador -->
                    <treeselect id="package" v-model="package.data" :defaultOptions="package.all"
                        :load-options="searchPackage" :async="true" :normalizer="normalizer" />
                </b-form-group>
            </b-col>
        </b-row>
        <div v-if="package.data != null">
            <b-row>
                <b-col>
                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">Laboratories</h5>
                    </b-col>
                    <b-col>
                        <treeselect id="package" v-model="laboratory" :defaultOptions="laboratories" :async="true"
                            :normalizer="normalizer" multiple disabled />
                    </b-col>
                </b-col>
                <b-col>
                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">Interventions</h5>
                    </b-col>
                    <b-col>
                        <treeselect id="package" v-model="intervention" :defaultOptions="interventions" :async="true"
                            :normalizer="normalizer" multiple disabled />
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">Treatments</h5>
                    </b-col>
                    <b-col>
                        <treeselect id="package" v-model="treatment" :defaultOptions="treatments" :async="true"
                            :normalizer="normalizer" multiple disabled />
                    </b-col>
                </b-col>
                <b-col>
                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">Diagnostic imaging</h5>
                    </b-col>
                    <b-col>
                        <treeselect id="package" v-model="diagnostic" :defaultOptions="diagnostics" :async="true"
                            :normalizer="normalizer" multiple disabled />
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">Tests</h5>
                    </b-col>
                    <b-col>
                        <treeselect id="package" v-model="test" :defaultOptions="tests" :async="true"
                            :normalizer="normalizer" multiple disabled />
                    </b-col>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import axiosAI from "@/core/services/apiInsurance/admin/consults/packages";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormRadioGroup,
        BFormTextarea,
        BFormInvalidFeedback,
    },
    props: {
        package: {
            type: Object,
            required: true,
        },
        laboratories: {
            type: Array,
            required: true,
        },
        interventions: {
            type: Array,
            required: true,
        },
        treatments: {
            type: Array,
            required: true,
        },
        diagnostics: {
            type: Array,
            required: true,
        },
        tests: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            laboratory: null,
            intervention: null,
            treatment: null,
            diagnostic: null,
            test: null,
            price: 0,
            xd: [],
            options: [
                { text: "Normal", value: "normal", disabled: false },
                { text: "Abnormal", value: "abnormal", disabled: false },
            ],
            isAddNewDiagnosesSidebarActive: false,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    created() {
        this.$parent.$on('getPackageData', this.getPackageData())
    },
    watch: {
        package: {
            handler() {
                this.getPackageData()
            },
            deep: true
        },
    },
    methods: {
        getPackageData() {
            if (this.package.data != null) {
                axiosAI.getPackage(this.package.data).then(async ({ data: { laboratories, diagnostic_imagings, procedures, treatments, tests, price } }) => {
                    this.laboratory = await laboratories.map(laboratory => {
                        return laboratory.id
                    })
                    this.diagnostic = await diagnostic_imagings.map(laboratory => {
                        return laboratory.id
                    })
                    this.intervention = await procedures.map(laboratory => {
                        return laboratory.id
                    })
                    this.treatment = await treatments.map(laboratory => {
                        return laboratory.id
                    })
                    this.test = await tests.map(laboratory => {
                        return laboratory.id
                    })
                    this.price = price
                    this.$emit('pricePackage', price)
                })
            } else {
                this.price = 0
                this.$emit('pricePackage', 0)
            }
        },
        searchPackage({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                clearTimeout(this.setTimeoutBuscador);
                const datos = {
                    name: searchQuery,
                    status: true,
                };
                this.setTimeoutBuscador = setTimeout(() => {
                    axiosAI.packageFilter(10, datos).then(({ data: { data } }) => {
                        callback(null, data);
                    });
                }, 350);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.text-area-assesment {
    height: calc(3em + 1.3rem + 2px) !important;
}
</style>
